//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Header: () => import("@/components/Header"),
    ProgressGroup: () => import("./progressGroup"),
    processRule: () => import("./processRule"),
    processMarkManager: () => import("./processMarkManager"),
    windowAliasManager: () => import("./windowAliasManager"),
    ActionRule: () => import("./actionRule"),
    SensitiveWords: () => import("./sensitiveWords"),
    ShieldingSoftware: () => import("./shieldingSoftware"),
    UrlSet: () => import("./urlSet"),
  },
  props: {
    selRow: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    tabType: 6,
    tabList: [
      {
        value: 6,
        label: "敏感词",
      },
      {
        value: 7,
        label: "屏蔽的软件",
      },
      {
        value: 1,
        label: "部门设置",
      },
      {
        value: 5,
        label: "行为规则设置",
      },
      {
        value: 2,
        label: "进程规则设置",
      },
      {
        value: 8,
        label: "网络请求分类",
      },
      {
        value: 3,
        label: "进程标记",
      },
      {
        value: 4,
        label: "窗口别名",
      },
    ],
  }),
  methods: {
    tabChange(value) {
      this.tabType = value;
    },
  },
};
